/* purgecss start ignore */

.bannerContainer{
  background-image:url("/images/banner.webp");
  background-position:center;
  background-size:cover;
  background-repeat:no-repeat;
  min-height:247px;
  position:relative;
  display:flex;
  width:100%;
  flex-direction:column;
  align-items:center;
  padding:0.75rem
}

.title{
  text-align:center;
  font-size:24px;
  line-height:36px;
  --tw-text-opacity:1;
  color:rgba(36, 89, 173, var(--tw-text-opacity))
}

@media (min-width: 1024px){
  .title{
    text-align:left;
    font-size:3.75rem;
    line-height:1
  }
}

.subTitle{
  margin-top:4px;
  text-align:center;
  font-family:Mulish, bold;
  font-size:11px;
  line-height:13.81px;
  font-weight:600;
  --tw-text-opacity:1;
  color:rgba(36, 89, 173, var(--tw-text-opacity))
}

@media (min-width: 1024px){
  .subTitle{
    margin-top:16px;
    font-size:15px;
    font-weight:700
  }
}

.innerBanner{
  max-width:1200px;
  padding-top:73px;
  z-index:10;
  margin-top:4rem;
  display:flex;
  height:100%;
  width:100%;
  flex-direction:column;
  align-items:center;
  padding-left:12px;
  padding-right:12px
}

@media (min-width: 1024px){
  .innerBanner{
    align-items:flex-start
  }
}

.searchBarDesktop{
  margin-top:64px;
  display:none
}

@media (min-width: 1024px){
  .searchBarDesktop{
    display:flex
  }
}

.searchBarMobile{
  margin-top:70px;
  margin-bottom:16px;
  display:flex
}

@media (min-width: 1024px){
  .searchBarMobile{
    display:none
  }
}

.bannerImg{
  position:absolute;
  top:0px;
  height:100%;
  width:100%
}

@media(min-width: 1024px){
  .bannerContainer{
    min-height:600px
  }

  .bannerImg{
    min-height:600px
  }

  .innerBanner{
    padding:0 24px 0
  }
}

.lineSearch{
  border-color:#2459ad !important
}

/* purgecss end ignore */