@import "~@styles/variables.scss";
.bannerContainer {
  background-image: url('/images/banner.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 247px;
  @apply relative flex w-full flex-col items-center p-3
}

.title {
  @apply text-24 text-center text-blue-main lg:text-left  lg:text-6xl
}

.subTitle {
  @apply text-11 text-blue-main font-textBold text-center font-semibold mt-4px lg:text-15 lg:font-bold lg:mt-16px
}

.innerBanner {
    max-width: $page-max-width;
    padding-top: 73px;
    @apply z-10 w-full h-full flex flex-col items-center lg:items-start px-12px mt-16
}

.searchBarDesktop {
  margin-top: 64px;
  @apply hidden lg:flex
}

.searchBarMobile {
  margin-top: 70px;
  @apply flex lg:hidden mb-16px
}

.bannerImg {
  @apply absolute top-0 w-full h-full
}

@media (min-width: 1024px) {
  .bannerContainer {
    min-height: 600px;
  }
  .bannerImg {
    min-height: 600px;
  }
  .innerBanner {
    padding: 0 24px 0;
  }
}

.lineSearch {
  border-color: #2459ad!important;
}
